import { ApisauceInstance, create } from "apisauce";
import { SelectlineUser } from "../classes/SelectlineUser";
import {
    DocumentPositionQuantityUnit,
    ExtraFields,
    selectlineApi,
    SelectlineArticleRequestObject,
    SlApiErrorResponse,
} from "../interfaces/selectlineInterfaces";
import { ExportArticle } from "../models/export-article";
import { ExportComment } from "../models/export-comment";
import tamperArticleFields from "../utilities/TamperArticleFields";

class SelectlineApi implements selectlineApi {
    private _url!: string;
    private readonly _connector: ApisauceInstance;
    private _selectlineUser!: SelectlineUser;

    constructor() {
        this.setUrl(`${process.env.REACT_APP_SELECTLINE_API_URL}`);
        this._connector = create({
            baseURL: `${this.getUrl()}`,
            headers: {
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                apiKey: `${process.env.REACT_APP_SELECTLINE_MIDDLEWARE_SERVER_API_KEY}`,
            },
        });
    }

    getSelectlineUser(): SelectlineUser {
        return this._selectlineUser;
    }

    setSelectlineUser(value: SelectlineUser) {
        this._selectlineUser = value;
    }

    getUrl(): string {
        return this._url;
    }

    setUrl(value: string) {
        this._url = value;
    }

    getConnector(): ApisauceInstance {
        return this._connector;
    }

    savePartialSum(documentKey: string) {
        return new Promise<void>((resolve, reject) => {
            this._connector
                .post(`Documents/${documentKey}/SubTotalPosition`, {
                    DiscountPercent: "0",
                })
                .then((response: any) => {
                    if (!response.ok) {
                        reject(response.data?.message);
                    }
                    resolve(response.status);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    addOrderConfig(documentKey: string) {
        return new Promise<void>((resolve, reject) => {
            this._connector
                .post(`Documents/${documentKey}/orderConfig`, {
                    PriceGroupNumber: 6,
                    DiscountAmount: 0,
                    DiscountGroupNumber: 0,
                })
                .then((response: any) => {
                    if (!response.ok) {
                        reject(response.data?.message);
                    }
                    resolve(response.status);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    fetchArticleQuantityUnits(articleNumber: string, amount: number, unit: string | undefined) {
        return new Promise<number>((resolve, reject) => {
            if (!unit) {
                return resolve(amount);
            }

            this._connector
                .get<DocumentPositionQuantityUnit[], SlApiErrorResponse>(
                    `omni/Articles/${encodeURIComponent(articleNumber).replace(/%/g, "~")}/QuantityUnits`,
                )
                .then((response) => {
                    if (!response.ok) {
                        reject(response.data?.message ?? "Unknown error");
                        return;
                    }

                    const documentPositionQuantityUnit = response.data?.find((item) => {
                        return item.QuantityUnit === unit;
                    });

                    if (!documentPositionQuantityUnit) {
                        resolve(amount);
                        return;
                    }

                    const calculatedQuantity = Math.ceil(amount / documentPositionQuantityUnit.QuantityFactor);

                    console.log(`changed amount from ${amount} to ${calculatedQuantity}`);

                    resolve(calculatedQuantity);
                })
                .catch((error) => {
                    reject(error.message || "Network error");
                });
        });
    }

    saveArticle(article: ExportArticle, documentKey: string) {
        return new Promise<void>((resolve, reject) => {
            const extra: ExtraFields = Object.create(null);
            if (article.getAlternative()) {
                extra["AlternativPos"] = "a";
            }

            const foreignKey = article.getForeignKey();
            if (foreignKey !== undefined) {
                extra["_ZEICHNR"] = foreignKey;
            }

            //recalculate amount to match default unit
            this.fetchArticleQuantityUnits(article.getId(), article.getAmount(), article.getUnit())
                .then((amount) => {
                    let dataObject: SelectlineArticleRequestObject = {
                        ArticleNumber: article.getName(),
                        CalculatedQuantityValue: amount,
                        ExtraFields: extra,
                    };

                    dataObject = tamperArticleFields(article, dataObject);

                    this._connector
                        .post(`Documents/${documentKey}/ArticleItem`, dataObject)
                        .then((response: any) => {
                            if (!response.ok) {
                                reject(response.data?.message);
                            }
                            resolve(response.status);
                        })
                        .catch(() => {
                            reject();
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    addProjectIdToDocument(documentKey: string, projectId: string) {
        const rawBody = JSON.stringify({
            ExtraFields: {
                _QSBEMERKUNG: projectId,
                // TODO: set _BVBEZEICHNUNG
            },
        });

        return new Promise<void>((resolve, reject) => {
            this._connector
                .put(`Documents/${documentKey}`, rawBody, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((response: any) => {
                    if (!response.ok) {
                        reject(response.data?.message);
                    }
                    resolve(response.status);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    saveComment(documentKey: string, comment: ExportComment) {
        return new Promise<void>((resolve, reject) => {
            this._connector
                .post(
                    `Documents/${documentKey}/CommentPosition`,
                    {
                        name: comment.getName(),
                        additionalDescription: comment.getAdditionalDescription(),
                        memo: comment.getMemo(),
                    },
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                        },
                    },
                )
                .then((response: any) => {
                    if (!response.ok) {
                        reject(response.data?.message);
                    }
                    resolve(response.status);
                })
                .catch(() => {
                    reject();
                });
        });
    }
}

const selectlineAPI = new SelectlineApi();
export default selectlineAPI;
